import { parseMessageData } from 'scripts/utils/parseMessageData';
import {
  isSessionStorageSupported,
  storageAvailable,
} from 'scripts/utils/storage';

/**
 * On initial load, clears the session storage key that was dropped
 * if a user has dismissed Continuous Play in the Player UI
 */
const clearSession = () => {
  if (
    isSessionStorageSupported &&
    storageAvailable('sessionStorage') &&
    sessionStorage.getItem('hide-continuous-play')
  ) {
    sessionStorage.removeItem('hide-continuous-play');
  }
};

interface PlayerPostMessage {
  data: Record<string, unknown>;
  origin: string;
}

/**
 * Handles post messages, looks for one for Continuous Play (aka Up Next)
 */
const onReceiveMessage = (e: PlayerPostMessage): void => {
  const data = parseMessageData(e.data as unknown as string);

  if (!data) {
    return;
  }

  const allowedOrigins = [
    'http://localhost:8000',
    'https://player.localhost:8080',
    'https://player-staging.pbs.org',
    'https://player.pbs.org',
  ];

  const isContinuousPlayPostMessage =
    // does the message come from player?
    // adding this as a security check
    allowedOrigins.indexOf(e.origin) !== -1 &&
    // does it indicate that this is a continuous play command?
    (
      data.command === `navigate-to-continuous-play-video` ||
      data.command === `hide-continuous-play`
    );

  if (isContinuousPlayPostMessage) {
    const payload = data.payload;

    if (data.command === `hide-continuous-play`) {
      // if session storage isn't supported/available, die peacefully
      if (!isSessionStorageSupported || !storageAvailable('sessionStorage')) {
        return null;
      }
      // but if it is, set `hide-continuous-play` to `true`
      sessionStorage.setItem('hide-continuous-play', 'true');
    } else if (payload && payload.slug) {
      // redirect the users to the slug indicated by the post message
      // the continuousplayautoplay query parameter is used in constructing the iframe on the next page
      // see pbs-player-iframe.js
      window.location.href = `/video/${payload.slug}?continuousplayautoplay=true`;
    }
  }
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  window.addEventListener('load', clearSession);
  window.addEventListener('message', onReceiveMessage, false);
};

const continuousPlayInit = (): void => {
  addEvents();
};

export { continuousPlayInit };
