/**
 * Cache for referenced elements.
 * @type {object} cache
 */
interface CacheExpectations {
  fixedIframeText?: HTMLInputElement,
  embedWidth?: HTMLInputElement,
  embedHeight?: HTMLInputElement,
  copyButtons?: NodeListOf<HTMLButtonElement>,
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.embedWidth = document.querySelector('#embed-width');
  cache.embedHeight = document.querySelector('#embed-height');
  cache.fixedIframeText = document.querySelector('#fixed-iframe-text');
  cache.copyButtons = document.querySelectorAll('.embed-modal__copy-button');
};

/**
 * Config for default embed player values.
 * Carried over from the player itself:
 * https://projects.pbs.org/bitbucket/projects/AP/repos/pbs-player/browse/src/topbar/embed/embed-screen.tsx#10
 * @type {object} config
 */
const config = {
  aspectRatio: 1.77, // 16:9
  minWidth: 320,
  minHeight: 224,
  topBarHeight: 43 // height of the viral player top bar, which needs to be accounted for
};

/**
 * Pulls in video id from data attribute on input element.
 * This needs to be the legacy tp media id in order to create
 * a functioning player URL.
 * @returns {string} - video cid
 */
const getVideoId = (): string => {
  return cache.fixedIframeText.getAttribute('data-video-legacy-id');
};

/**
 * Replaces existing embed code with updated values.
 * @param {number} w - video player width in pixels
 * @param {number} h - video player height in pixels
 * @returns {string} - new embed code
 */
const getUpdatedEmbedCode = (w:number, h:number, depVideoCid?:string): string => {
  const W = w >= config.minWidth ? w : config.minWidth;
  const H = h >= config.minHeight ? h : config.minHeight;
  const videoCid = depVideoCid || getVideoId();

  return `<iframe width="${W}" height="${H}" src="https://player.pbs.org/viralplayer/${videoCid}/" allowfullscreen allow="encrypted-media" style="border: 0;"></iframe>`;
};

/**
 * Calculates height based on width and aspect ratio.
 * @param {number} w - video player width in pixels
 * @returns {number} - new height corresponding to provided width
 */
const getHeight = (w:number): number => {
  return Math.round(w / config.aspectRatio + config.topBarHeight);
};

/**
 * Calculates width based on height and aspect ratio.
 * @param {number} h - video player height in pixels
 * @returns {number} - new width corresponding to provided height
 */
const getWidth = (h:number): number => {
  return Math.round((h - config.topBarHeight) * config.aspectRatio);
};

/**
 * Handles a user manually updating the height value;
 * Updates width and embed code to match.
 */
const updateHeight = (e: Event): void => {
  let h = parseInt(cache.embedHeight.value);

  // if the user inputs something other than a number,
  // or a very small number,
  // let's handle that by resetting the value to the minimum
  // note - empty strings pass isNaN -
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN#Confusing_special-case_behavior
  if (isNaN(h) || (h < config.minHeight && e.type === 'change')) {
    h = config.minHeight;
    cache.embedHeight.value = h.toString();
  }

  const w = getWidth(h);

  cache.embedWidth.value = w.toString();
  cache.fixedIframeText.value = getUpdatedEmbedCode(w, h);
};

/**
 * Handles a user manually updating the width value;
 * Updates height and embed code to match.
 * @param {Event} e - change event
 */
const updateWidth = (e:Event): void => {
  let w = parseInt(cache.embedWidth.value);

  // if the user inputs something other than a number,
  // or a very small number,
  //let's handle that by resetting the value to the minimum
  if (isNaN(w) || (w < config.minWidth && e.type === 'change')) {
    w = config.minWidth;
    cache.embedWidth.value = w.toString();
  }

  const h = getHeight(w);

  cache.embedHeight.value = h.toString();
  cache.fixedIframeText.value = getUpdatedEmbedCode(w, h);
};


/**
 * Shows a message based on whether copying was successful or not
 * @param {boolean} success - whether or not copying worked
 */
const showCopyMessage = (success: boolean, button: HTMLButtonElement): void => {
  const defaultMessage = button.querySelector('.copy-button-text--default');
  const successMessage = button.querySelector('.copy-button-text--success');
  const errorMessage = button.querySelector('.copy-button-text--error');

  if (success) {
    button.classList.toggle('success');
    defaultMessage.classList.toggle('is-hidden');
    successMessage.classList.toggle('is-hidden');
  } else {
    errorMessage.classList.toggle('is-hidden');
  }

  setTimeout(() => {
    button.classList.remove('success');
    defaultMessage.classList.remove('is-hidden');
    successMessage.classList.add('is-hidden');
    errorMessage.classList.add('is-hidden');
  }, 3000);
};

/**
 * Event handler for when a user click a copy button.
 * It will select the right input, then attempt a copy, and then
 * pass information to our message display function
 * @param {Event} e - the click event
 */
const onCopyButton = (e:Event): void => {
  const button = e.target as HTMLButtonElement;
  const copyTargetId = `#${button.getAttribute(`data-copy-target`)}`;
  const copyTarget = document.querySelector(copyTargetId) as HTMLInputElement;

  copyTarget.focus();
  copyTarget.select();

  try {
    document.execCommand('copy');
    showCopyMessage(true, button);
  } catch (err) {
    showCopyMessage(false, button);
  }
};

/**
 * Checks for copy & pastes support - if it works
 * we reveal the copy buttons, and attaches click event listeners
 * to those buttons.
 */
const checkCopySupport = (): void => {
  const supported = document.queryCommandSupported('copy');

  if (supported) {
    cache.copyButtons.forEach(button => {
      button.classList.remove('is-hidden');
      button.addEventListener('click', onCopyButton);
    })
  }
};

/**
 * Adds event handlers.
 */
const addEvents = (): void => {
  cache.embedHeight.addEventListener('keyup', updateHeight);
  cache.embedHeight.addEventListener('change', updateHeight);
  cache.embedWidth.addEventListener('keyup', updateWidth);
  cache.embedWidth.addEventListener('change', updateWidth);
};

/**
 * Inits embed modal functions.
 */
const init = (): void => {
  setupCache();
};

/**
 * Handler for when the modal is shown. At *that* point
 * we attach event handlers and check copy support
 */
const onShow = (): void => {
  addEvents();
  checkCopySupport();
};

/**
 * Deletes event listeners and cache when modal is closed.
 */
const destroy = (): void => {
  cache.embedHeight.removeEventListener('keyup', updateHeight);
  cache.embedHeight.removeEventListener('change', updateHeight);
  cache.embedWidth.removeEventListener('keyup', updateWidth);
  cache.embedWidth.removeEventListener('change', updateWidth);

  cache.copyButtons.forEach((button) => {
    button.removeEventListener('click', onCopyButton)
  });
};

export { init, destroy, onShow, getUpdatedEmbedCode, getHeight, getWidth };
