import _debounce from 'lodash.debounce';

interface CacheExpectations {
  floatingPlayerContainer?: HTMLElement;
  iframeWrapper?: HTMLElement;
  floatingPlayer?: HTMLElement;
  closeButton?: HTMLElement;
  upButton?: HTMLElement;
  videoHeight?: number;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.floatingPlayerContainer = document.querySelector('.floating-player-container');
  cache.iframeWrapper = document.querySelector('.floating-player__iframe-wrapper');
  cache.floatingPlayer = document.querySelector('.floating-player');
  cache.closeButton = document.querySelector('.floating-player__video-close');
  cache.upButton = document.querySelector('.floating-player__video-up');
  cache.videoHeight = cache.iframeWrapper?.getBoundingClientRect().height;
};

/**
 * Determines if user has scrolled down;
 * if so, we apply the floating behavior.
 */
 const onWindowScroll = () => {
  // if user has interacted with the video,
  // has scrolled down past the height of the video,
  // and we haven't already applied the 'dont-float' class
  if (
      cache.floatingPlayer.classList.contains('can-float')
      && (window.scrollY > cache.videoHeight)
      && !cache.floatingPlayer.classList.contains('dont-float')
    ) {
    floatPlayer();
  } else {
    // if user scrolls up, return it to its proper place
    returnPlayerToTop();
  }
}

/**
 * Modifies player element classes to make it
 * "float" to the bottom right corner of the window.
 */
const floatPlayer = () => {
  cache.floatingPlayer.classList.add('float');
  // un-hide the close & up buttons
  cache.closeButton.classList.remove('is-hidden');
  cache.upButton.classList.remove('is-hidden');
}

/**
 * Modifies player element classes to make it
 * return to the top of the page.
 */
const returnPlayerToTop = () => {
  // remove the class that floats the player
  cache.floatingPlayer.classList.remove('float');
  // hide the close & up buttons
  cache.closeButton.classList.add('is-hidden');
  cache.upButton.classList.add('is-hidden');
}

/**
 * Parses incoming postMessages to check for user
 * interaction with the player.
 */
const onReceiveMessage = (e: MessageEvent) => {
  const isPlayerVideoInteractionPostMessage =
    // does the message come from player?
    // adding this as a security check
    e.origin.indexOf('player.pbs.org') !== -1 &&
    // is the event a user interaction (play/pause)?
    (e.data === '{"event":"videojs:play"}' ||
    e.data === '{"event":"videojs:pause"}');

  if (isPlayerVideoInteractionPostMessage) {
    // let the player float
    cache.floatingPlayer.classList.add('can-float');
  }
}

/**
 * Handles a manual click to the up button.
 */
 const handleUpClick = () => {
  // return player to top of page
  returnPlayerToTop();
  // scroll user to top of page
  window.scrollTo(0, 0);
}

/**
 * Handles a manual click to the close button.
 */
 const handleCloseClick = () => {
  // add a class to prevent the player from floating again
  cache.floatingPlayer.classList.add('dont-float');
  // return player to top of page
  returnPlayerToTop();
}

/**
 * Adds event handlers.
 */
const addEvents = () => {
  if (cache.floatingPlayerContainer) {
    window.addEventListener('scroll', _debounce(onWindowScroll, 50));
    window.addEventListener('message', onReceiveMessage, false);
    cache.upButton.addEventListener('click', handleUpClick);
    cache.closeButton.addEventListener('click', handleCloseClick);
  }
}

const init = (): void => {
  setupCache();
  addEvents();
};

export { init };
