// Based on https://codepen.io/heydon/pen/veeaEa
interface CacheExpectations {
  tablist?: HTMLUListElement;
  tabs?: NodeListOf<HTMLAnchorElement>;
  panels?: NodeListOf<HTMLDivElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} _setupCache
 */
const setupCache = () => {
  cache.tablist = document.querySelector('.tablist');
  cache.tabs = cache.tablist?.querySelectorAll('a');
  cache.panels = document.querySelectorAll('.panel');
};

// The tab switching function
const switchTab = (oldTab: HTMLAnchorElement, newTab: HTMLAnchorElement) => {
  newTab.focus();
  // Make the active tab focusable by the user (Tab key)
  newTab.removeAttribute('tabindex');
  // Set the selected state
  newTab.setAttribute('aria-selected', 'true');
  oldTab.removeAttribute('aria-selected');
  oldTab.setAttribute('tabindex', '-1');
  // Get the indices of the new and old tabs to find the correct
  // tab panels to show and hide
  const index = Array.prototype.indexOf.call(cache.tabs, newTab);
  const oldIndex = Array.prototype.indexOf.call(cache.tabs, oldTab);
  cache.panels[oldIndex].hidden = true;
  cache.panels[index].hidden = false;
}

// Add semantics are remove user focusability for each tab
const setupTabs = () => {
  cache.tabs.forEach((tab, i) => {
    tab.setAttribute('role', 'tab');
    tab.setAttribute('id', 'tab' + (i + 1));
    tab.setAttribute('tabindex', '-1');

    const tabParent = tab.parentNode as HTMLElement;
    tabParent.setAttribute('role', 'presentation');

    // Handle clicking of tabs for mouse users
    tab.addEventListener('click', e => {
      e.preventDefault();
      const currentTab: HTMLAnchorElement = cache.tablist.querySelector('[aria-selected]');
      if (e.currentTarget !== currentTab) {
        switchTab(currentTab, e.currentTarget as HTMLAnchorElement);
      }
    });

    // Handle keydown events for keyboard users
    tab.addEventListener('keydown', e => {
      // Get the index of the current tab in the tabs node list
      const index = Array.prototype.indexOf.call(cache.tabs, e.currentTarget);
      // Work out which key the user is pressing and
      // Calculate the new tab's index where appropriate
      const dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
      if (dir !== null) {
        e.preventDefault();
        const currentTarget = e.currentTarget as HTMLAnchorElement;
        // If the down key is pressed, move focus to the open panel,
        // otherwise switch to the adjacent tab
        dir === 'down' ? cache.panels[i].focus() : cache.tabs[dir] ? switchTab(currentTarget, cache.tabs[dir]) : void 0;
      }
    });
  });
}

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();

  // only do all the rest of we actually have tabs on page
  if (cache.tablist) {
    // Add the tablist role to the first <ul> in the .tabbed container
    cache.tablist.setAttribute('role', 'tablist');

    setupTabs();

    // Add tab panel semantics and hide them all
    cache.panels.forEach((panel, i) => {
      panel.setAttribute('role', 'tabpanel');
      panel.setAttribute('tabindex', '-1');
      panel.setAttribute('aria-labelledby', cache.tabs[i].id);
      panel.hidden = true;
    });

    // Initially activate the first tab and reveal the first tab panel
    cache.tabs[0].removeAttribute('tabindex');
    cache.tabs[0].setAttribute('aria-selected', 'true');
    cache.panels[0].hidden = false;
  }
};

export { init };
