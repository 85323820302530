import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'components/page-header/js/page-header';
import 'scripts/modules/external-links';
import 'components/page-footer/page-footer';
import { customEvents } from 'scripts/custom-events';
import { init as adsInit } from 'components/ad/ads';
import { init as contentStripEpisodesInit } from 'components/content-strip/varieties/episodes/episodes-strip';
import { init as contentStripSpecialsInit } from 'components/content-strip/varieties/specials/specials-strip';
import { init as contentStripExtrasInit } from 'components/content-strip/varieties/extras/extras-strip';
import { init as contentStripCollectionsInit } from 'components/content-strip/varieties/collections/collections-strip';
import { init as contentStripVideosInit } from 'components/content-strip/varieties/videos/videos-strip';
import { init as showRowCarouselInit } from 'components/show-row/show-row';
import { init as playerVideoInit } from 'components/video/player/video';
import { init as fundingInit } from 'components/funding/funding';
import { init as myListButtonInit } from 'components/my-list/my-list-button/components/button-group';
import { init as continuousPlayControlInit } from 'components/video/player/continuous-play-control';
import { init as relatedContentInit } from 'components/related-content/related-content';
import { continuousPlayInit } from 'scripts/modules/continuous-play';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { pbsPlayerIframeInit } from 'scripts/modules/pbs-player-iframe';
import { WindowPBS } from 'scripts/modules/window.PBS';
import { init as getUserViewingHistoryInit } from 'scripts/modules/get-user-viewing-history';
import { init as viewingHistoryProgressInit } from 'components/viewing-history-progress/viewing-history-progress';
import { init as tabsInit } from 'components/tabs/tabs';
import { init as transcriptInit } from 'components/transcript/transcript';

declare let window: WindowPBS;

// As of RWEB-8009 / PLYR-875, we need to wait until we know the user's Passport status
// before we initialize any ad calls. That means waiting for this custom event before
// we try to initialize the player, so that the user's Passport status can be passed along
// to the ad calls that are made on the player side.
const addEvents = (): void => {
  window.addEventListener(customEvents.userStatusIsKnown, () => pbsPlayerIframeInit(window.PBS.playerConfig));
}

addEvents();
adsInit();
contentStripEpisodesInit();
contentStripSpecialsInit();
contentStripExtrasInit();
contentStripCollectionsInit();
contentStripVideosInit();
showRowCarouselInit();
playerVideoInit();
fundingInit();
myListButtonInit();
continuousPlayControlInit();
continuousPlayInit();
relatedContentInit();
localizationEventsInit();
getUserViewingHistoryInit();
viewingHistoryProgressInit();
tabsInit();
transcriptInit();
