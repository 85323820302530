import _debounce from 'lodash.debounce';

import {
  isSessionStorageSupported,
  storageAvailable,
} from 'scripts/utils/storage';

interface CacheExpectations {
  theatreModeContainer?: HTMLElement;
  iframe?: HTMLElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.theatreModeContainer = document.querySelector('.theatre-mode-container');
  cache.iframe = cache.theatreModeContainer?.querySelector('iframe');
};

/**
 * On page load, check if the user has saved a Theatre Mode preference in
 * sessionStorage, and apply the layout accordingly.
 */
const setInitialLayout = () => {
  if (isTheatreModeOn()) {
    toggleTheatreMode();
  }
}

/**
 * When the player first loads, send it postMessages about the page's size
 * and whether or not the user has already turned on Theatre Mode.
 */
const onPlayerLoad = () => {
  // Tell Player whether or not to display the Theatre Mode control button
  handleWindowSize();

  if (isTheatreModeOn()) {
    // Tell Player to adjust the button's state if Theatre Mode is already on
    const message = JSON.stringify({
      command: 'theatreModeIsOn'
    });
    sendPostMessage(message);
  }
}

/**
 * Check window size and assess whether or not Theatre Mode toggle button should display.
 */
const handleWindowSize = () => {
  const pageWidth = window.innerWidth;

  if (pageWidth >= 1024) {
    // If page is wide enough, show Theatre Mode toggle button
    const message = JSON.stringify({
      command: 'showTheatreModeControls'
    });
    sendPostMessage(message);
  } else {
    // Otherwise, hide Theatre Mode toggle button
    const message = JSON.stringify({
      command: 'hideTheatreModeControls'
    });
    sendPostMessage(message);
  }
}

/**
 * Allows us to test this feature with different versions of player.
 */
const allowedOrigins = [
  'https://player.localhost:8080',
  'https://player-staging.pbs.org',
  'https://player.pbs.org',
];

/**
 * Parses incoming postMessages to check for user
 * interaction with the player's Theatre Mode control button.
 */
const onReceiveMessage = (e: MessageEvent) => {
  const isPlayerTheatreModeControlPostMessage =
    // does the message come from player?
    // adding this as a security check
    allowedOrigins.indexOf(e.origin) !== -1 &&
    // does the event toggle Theatre Mode?
    e.data === '{"event":"videojs:toggleTheatreMode"}';

  if (isPlayerTheatreModeControlPostMessage) {
    toggleTheatreMode();
  }
}

/**
 * Enters or exits Theatre Mode by applying/removing classes
 * that control video playback page layout.
 */
const toggleTheatreMode = () => {
  const theatreModeClasses = cache.theatreModeContainer.classList;

  if (theatreModeClasses.contains('theatre-mode-layout-enabled')) {
    // if Theatre Mode is currently on, turn it off
    theatreModeClasses.remove('theatre-mode-layout-enabled');
    updateSessionStorage('off');
  } else {
    // if it's currently off, turn it on
    theatreModeClasses.add('theatre-mode-layout-enabled');
    updateSessionStorage('on');
  }
}

/**
 * Saves the current Theatre Mode state in sessionStorage.
 */
const updateSessionStorage = (state: 'on' | 'off') => {
  if (
    isSessionStorageSupported &&
    storageAvailable('sessionStorage')
  ) {
    sessionStorage.setItem('theatreMode', state);
  }
}

/**
 * Checks for an existing Theatre Mode preference in sessionStorage.
 */
const isTheatreModeOn = (): boolean => {
  if (isSessionStorageSupported) {
    const preference = sessionStorage.getItem('theatreMode');

    // return true if it's set to on in sessionStorage, false if not
    return preference === 'on';
  }

  // return false by default if we can't use sessionStorage for some reason
  return false;
}

/**
 * Sends a postMessage to the Player to show or hide the Theatre Mode control button,
 * or to let Player know Theatre Mode is already on (when reading from sessionStorage).
 */
const sendPostMessage = (message: string) => {
  const player = window.frames['player'] || window.frames['livestream-player'];

  if (player && typeof player.postMessage === 'function') {
    player.postMessage(message, '*');
  }
}

/**
 * Adds event handlers.
 */
const addEvents = () => {
  if (cache.theatreModeContainer && cache.iframe) {
    setInitialLayout();
    // when the player first loads or we resize the page, assess window size
    cache.iframe.addEventListener('load', onPlayerLoad);
    window.addEventListener('resize', _debounce(handleWindowSize, 250));
    // listen for postMessages from Player to handle interactions with control button
    window.addEventListener('message', onReceiveMessage, false);
  }
}

const init = (): void => {
  setupCache();
  addEvents();
};

export { init };
