import * as React from 'react'
const { useEffect, useRef } = React;

// a custom react hook for using a hook at an interval,
// taken wholesale from Dan Abramov:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/#just-show-me-the-code
// typescript version taken from here:
// https://gist.github.com/babakness/faca3b633bc23d9a0924efb069c9f1f5#gistcomment-3151400
type IntervalFunction = () => ( unknown | void )

function useInterval(callback: IntervalFunction, delay: number | null ): void {

  const savedCallback = useRef<IntervalFunction| null>( null )

  useEffect( () => {
    if (delay === null) return;
    savedCallback.current = callback
  } )

  useEffect( () => {
     if (delay === null) return;
    function tick() {
      if ( savedCallback.current !== null ) {
        savedCallback.current()
      }
    }
    const id = setInterval( tick, delay )
    return () => clearInterval( id )

  }, [ delay ] )
}
export { useInterval };
