/**
 * Parses data for a valid JSON object
 * @param {string} data - the data we want to parse, usually from a postmessage
 * @returns {object}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseMessageData = (data: string): Record<string, any> | undefined => {
  let parsedData;

  try {
    parsedData = JSON.parse(data);
  } catch {
    return;
  }

  return parsedData;
};

export { parseMessageData };
