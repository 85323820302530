interface CacheExpectations {
  mobileHiddenElements?: NodeListOf<Element>;
  mobileToggle?: HTMLElement;
}

const cache: CacheExpectations = {};

const setUpCache = (): void => {
  cache.mobileHiddenElements = document.querySelectorAll(
    '.related-content__mobile-hidden'
  );
  cache.mobileToggle = document.querySelector(
    '.related-content__mobile-toggle'
  );
};

const handleMobileToggleClick = () => {
  cache.mobileHiddenElements.forEach((element) => {
    element.classList.remove('related-content__mobile-hidden');
  });

  cache.mobileToggle.parentNode.removeChild(cache.mobileToggle);
};

const addEventHandlers = (): void => {
  // We can't be sure that the mobile toggle is on the page, so we need to check for it
  if (cache.mobileToggle !== null) {
    cache.mobileToggle.addEventListener('click', handleMobileToggleClick);
  }
};

const init = (): void => {
  setUpCache();
  addEventHandlers();
};

export { init };
