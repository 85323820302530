import Modal from '../../modal/modal';
import * as ReportProblemForm from '../forms/report-a-problem';
import * as EmbedVideoPlayer from 'scripts/modules/embed-video-player';
import * as SignInModal from 'components/sign-in/sign-in';
import { toggleVideoPlayback } from 'scripts/modules/pbs-player-iframe';
import { init as linkShareButtonInit } from 'scripts/modules/link-share-button';
import { init as floatingPlayerInit } from 'scripts/modules/floating-player';
import { init as theatreModeInit } from 'scripts/modules/theatre-mode';

interface ModalsExpectations {
  [modalKey: string]: Modal;
}

/**
 * @const modals
 * Object to store references to modals so we only create them once.
 */
const modals:ModalsExpectations = {
  embedModal: undefined,
  reportProblemModal: undefined,
  signInModal: undefined,
};

interface CacheExpectations {
  embedModalTrigger?: HTMLButtonElement;
  reportProblemTrigger?: HTMLButtonElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.embedModalTrigger = document.querySelector('.embed-modal-trigger');
  cache.reportProblemTrigger = document.querySelector('.video-issues');
};

/**
 * Sets up the Embed Modal for sharing the video embed code
 */
const setupEmbedModal = () => {
  // not all videos are set to allow embedding, so we need to check for
  // the embed modal trigger's existence before initializing the modal
  if (cache.embedModalTrigger) {
    const options = {
      modalId: '#embed-modal',
      modalTrigger: '.embed-modal-trigger',
      focusTarget: '#embed-modal__dialog',
      childView: EmbedVideoPlayer,
    };

    modals.embedModal = new Modal(options);
  }
};

/**
 * Sets up report a problem modal.
 */
const setupReportProblemModal = () => {
  const options = {
    modalId: '#reportProblemModal',
    modalTrigger: '.video-issues',
    focusTarget: '#report-problem__dialog',
    lastFocusableEl: '#close-report-problem',
    childView: ReportProblemForm,
  };

  modals.reportProblemModal = new Modal(options);
};

/**
 * Sets up the sign in modal.
 */
const setupSignInModal = () => {
  const options = {
    modalId: '#signInModalWindow',
    modalTrigger: '.btn--sign-in',
    focusTarget: '#sign-in__dialog',
    childView: SignInModal,
  };

  modals.signInModal = new Modal(options);
};

/**
 * Shows a modal.
 * @param {Event} e
 */
const showModal = (e: Event, modal: string) => {
  e.preventDefault();
  e.stopPropagation();

  if (modals[modal]) {
    modals[modal].show({});
  }
};

/**
 * Shows the Embed Modal when the specific trigger is clicked
 * @param {Event} e
 */
const onEmbedClick = (e: Event) => {
  showModal(e, 'embedModal');
};

/**
 * On report a problem link click.
 * @param {Event} e
 */
const onReportProblemClick = (e: Event) => {
  showModal(e, 'reportProblemModal');
};

/**
 * what to do if the player posts a message
 * @param {Event} e
 */
const onReceiveMessage = (e: MessageEvent) => {
  const isPlayerSignInPostMessage =
    // does the message come from player?
    // adding this as a security check
    e.origin.indexOf('player.pbs.org') !== -1 &&
    // does it indicate that the user hit "sign in"?
    // this is the most brittle point of this feature.
    // if the sign in button stops working in player,
    // it likely changed this message data
    e.data === 'gtm-event:Passport,Sign In';

  if (isPlayerSignInPostMessage) {
    // show the sign in modal!
    showModal(e, 'signInModal');
  }
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  if (cache.embedModalTrigger) {
    // not all videos are set to allow embedding, so we need to check for
    // the embed modal trigger's existence before adding event listener
    cache.embedModalTrigger.addEventListener('click', onEmbedClick);
  }
  cache.reportProblemTrigger.addEventListener('click', onReportProblemClick);
  document.addEventListener('keypress', (e) => toggleVideoPlayback(e, 'player'));
  window.addEventListener('message', onReceiveMessage, false);
};

/**
 * Initializes.
 */
const init = (): void => {
  setupCache();
  addEvents();
  setupEmbedModal();
  setupReportProblemModal();
  setupSignInModal();
  linkShareButtonInit();
  floatingPlayerInit();
  theatreModeInit();
};

export { init };
