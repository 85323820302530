import { getCookieDomain } from 'scripts/utils/getCookieDomain';
import Cookies from 'js-cookie';

/**
 * Reference object for static data.
 * @type {object}
 */
const config = {
  hiddenClass: `report-problem__form-section--hidden`,
  cookieDomain: getCookieDomain(),
  csrftoken: Cookies.get('csrftoken'),
  contentType: 'application/x-www-form-urlencoded;charset=utf-8',
  requestedWith: 'XMLHttpRequest',
  url: '/feedback/submit/',
  defaultErrorMsg:
    'There was an error sending your request. Please try again later',
  maxChar: 500,
};

interface Params {
  [key: string]: string;
  videoTitle?: string;
  videoSlug?: string;
  videoLegacyId?: string;
  videoUrl?: string;
  playerType?: string;
  errorDetails?: string;
  errorType?: string;
}

const params: Params = {
  videoTitle: document.title,
  videoSlug: window.PBS.playerConfig.slug,
  videoLegacyId: window.PBS.playerConfig.id,
  videoUrl: window.location.href,
  playerType: window.PBS.playerConfig.embedType,
  errorDetails: '',
}
interface CacheExpectations {
  modal?: HTMLElement;
  formContainer?: HTMLElement;
  form?: HTMLElement;
  select?: HTMLSelectElement;
  detailsContainer?: HTMLElement;
  textarea?: HTMLTextAreaElement;
  messageContainer?: HTMLElement;
  message?: HTMLElement;
}

/**
 * Reference object for re-usable elements.
 * @type {object}
 */
const cache: CacheExpectations = {};

/**
 * Sets up _cache object of element references.
 */
const setupCache = () => {
  cache.modal = document.querySelector('.report-problem__dialog');
  cache.formContainer = document.querySelector(
    '.report-problem__form-container'
  );
  cache.form = cache.formContainer.querySelector('.report-problem__form');
  cache.select = cache.form.querySelector('.report-problem__form-select');
  cache.detailsContainer = cache.form.querySelector(
    '.report-problem__form-more-details'
  );
  cache.textarea = cache.detailsContainer.querySelector(
    '.report-problem__form-textarea'
  );
  cache.messageContainer = document.querySelector(
    '.report-problem__form-message-container'
  );
  cache.message = cache.messageContainer.querySelector(
    '.report-problem__form-message'
  );
};

/**
 * Shows textarea details.
 */
const showDetails = () => {
  cache.detailsContainer.classList.add('report-problem__form--visible');
};

/**
 * Hides textarea details.
 */
const hideDetails = () => {
  cache.detailsContainer.classList.remove('report-problem__form--visible');
};

/**
 * On select option changed.
 * @param {Event} e
 */
const onSelectChange = (e: Event) => {
  checkTextAreaVisibility(e.target as HTMLSelectElement);
};

/**
 * Check which option is selected and show a textarea if needed.
 * @param {HTMLSelectElement} select - the selected form option
 */
const checkTextAreaVisibility = (select: HTMLSelectElement) => {
  if (select) {
    const selectedIndex = select.selectedIndex;
    const selectedOption = select[selectedIndex];

    if (
      selectedOption &&
      selectedOption.classList.contains('report-problem__form-trigger')
    ) {
      showDetails();
    } else {
      hideDetails();
    }
  }
};

/**
 * Limits text length.
 * @param {event} e
 */
const limitText = (e: Event) => {
  // eslint is complaining that this isn't reassigned... but it seems to be to me.
  // given the low value of this component, it's not worth untangling.
  // eslint-disable-next-line prefer-const
  let timeout;

  clearTimeout(timeout);

  // delay the text triming, otherwise the last charcter will always change
  // and also for paste so value is set before we check how long it is
  timeout = setTimeout(() => {
    const input = e.target as HTMLInputElement;
    const value = input.value;

    if (value.length >= config.maxChar) {
      input.value = value.substr(0, config.maxChar);
    }
  }, 100);
};

/**
 * Checks request method.
 * @param {string} method - request method
 * @returns {boolean} if is safe method
 */
const csrfSafeMethod = (method: string): boolean => {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
};

interface fetchSettings {
  method?: string;
  crossDomain?: boolean;
  headers?: HeadersInit;
}

/**
 * On before send fetch request.
 * @param {settings} fetch request settings
 * @returns {object} Headers object
 */
const onBeforeFetch = (settings: fetchSettings) => {
  if (!csrfSafeMethod(settings.method) && !settings.crossDomain) {
    return new Headers({
      'X-CSRFToken': config.csrftoken,
      'Content-Type': config.contentType,
      'X-Requested-With': config.requestedWith,
    });
  }
};

/**
 * Shows message and hides form.
 * @param {string} msg - message to display
 */
const showMessage = (msg: string) => {
  cache.formContainer.classList.add(config.hiddenClass);
  cache.message.innerHTML = msg;
  cache.messageContainer.classList.remove(config.hiddenClass);
  cache.modal.focus();
};

const resetForm = () => {
  hideDetails();
  cache.formContainer.classList.remove(config.hiddenClass);
  cache.messageContainer.classList.add(config.hiddenClass);
  cache.select.selectedIndex = 0;
  cache.textarea.value = '';
};

/**
 * On successful fetch request
 * @param {object} data - data returned
 */
const onSubmitSuccess = (data: {errorMessage: string}) => {
  showMessage(data.errorMessage);
};

/**
 * On failed fetch request
 * @param {object} data - data returned
 */
const onSubmitError = (data: {errorMessage: string}) => {
  const msg = data.errorMessage || config.defaultErrorMsg;
  showMessage(msg);
};

/**
 * Builds payload to send for report a problem.
 * @returns {object} payload
 */
const buildPayload = () => {
  // const payload = Object.assign({}, params, {
  //   errorType: cache.select.value,
  // });

  const payload = params;

  const textareaValue = cache.textarea.value;

  if (textareaValue) {
    payload.errorDetails = textareaValue;
  }

  // we have to put together the x-www-form-urlencoded payload
  // since we are now using the fetch API.
  // https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
  const encodedPayload = Object.keys(payload)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])
    )
    .join('&');

  return encodedPayload;
};

/**
 * On form submit
 * @param {event} e
 */
const onFormSubmit = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  const payload = buildPayload();

  const settings: RequestInit = {
    method: 'POST',
    body: payload
  };

  const headers = onBeforeFetch(settings);
  settings.headers = headers;

  fetch(config.url, settings)
    .then((response) => {
      return response.json();
    })
    .then((success) => {
      onSubmitSuccess(success);
    })
    .catch((error) => {
      onSubmitError(error);
    });
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  cache.select.addEventListener('change', onSelectChange);
  cache.textarea.addEventListener('keypress', limitText);
  cache.textarea.addEventListener('paste', limitText);
  cache.form.addEventListener('submit', onFormSubmit);
};

/**
 * Removes event handlers.
 */
const removeEvents = () => {
  cache.select.removeEventListener('change', onSelectChange);
  cache.textarea.removeEventListener('keypress', limitText);
  cache.textarea.removeEventListener('paste', limitText);
  cache.form.removeEventListener('submit', onFormSubmit);
};

/**
 * Inits report a problem form.
 */
const init = (): void => {
  setupCache();
};

/**
 * On show public method.
 */
const onShow = (): void => {
  addEvents();

  // Check if we need the textarea to show up as soon as modal displays;
  // This is necessary for Firefox, which keeps old selected options cached even after reload:
  // http://stackoverflow.com/questions/10870567/firefox-not-refreshing-select-tag-on-page-refresh#answer-10870894
  checkTextAreaVisibility(cache.select);
};

/**
 * On hide public method.
 */
const onHide = (): void => {
  removeEvents();

  // reset form only if message is displayed
  if (cache.formContainer.classList.contains(config.hiddenClass)) {
    resetForm();
  }
};

export { init, onShow, onHide };
