import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import ContentStrip from '../../content-strip';
import LoadingIndicator from 'components/shared/loading-indicator';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { VideosStripProps } from '../../types/content-strip';
import { calcNumSlidesUp, createVideoSlides, getResponsiveOptions } from '../../utils/utils';

const VideosStrip: React.FC<VideosStripProps> = (props) => {
  const { carouselTitle, context, initialVideos, seeAllUrl } = props;
  const contentType = "videos";
  const width: number = useWindowWidth();
  const responsiveOptions = getResponsiveOptions(contentType); // defines 2-up/3-up/4-up layout breakpoints

  const [isFetching, setIsFetching] = useState(false);
  const [slides, setSlides] = useState([]);
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(contentType, width)); // gets the number of slides up based on responsiveoptions and initial width

  // on initial load, get the videos from the collection and add them as slides
  useEffect(() => {
    const shouldDisplayOverTitle = !['extras', 'episodes', 'specials'].includes(context);
    setIsFetching(true);
    const slides = createVideoSlides(
      initialVideos, shouldDisplayOverTitle
    ); // create video slides with includeOverTitle set to true
    setSlides(slides);
    setIsFetching(false);
  }, [context, initialVideos]);

  // when a change to the browser width is detected, alter the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(contentType, width));
  }, [width]);

  if (slides && slides.length > 0) {
    return (
      <ContentStrip
        carouselTitle={carouselTitle}
        contentType={contentType}
        fetchMoreSlides={() => Promise.resolve(null)}
        numSlidesUp={numSlidesUp}
        responsiveOptions={responsiveOptions}
        isFetching={isFetching}
        seeAllUrl={seeAllUrl}
        slides={slides}
      />
    )
  } else return (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
  );
};

const init = (): void => {
  // Check that the DOM element exists before trying to mount the component.
  // Note: unlike the others, this content strip looks for a class, not an id.
  // This is to allow for multiple Videos Strips to exist on the same page.
  const mountPoints = document.querySelectorAll('.videos-strip');
  const jsonNode = document.getElementById('content-strip-data');
  if (mountPoints.length > 0 && jsonNode) {
    const contentStripData = JSON.parse(jsonNode.textContent);
    // For each instance of .videos-strip on the page, render a Videos Strip
    mountPoints.forEach((mountPoint: HTMLElement, index: number) => {
      // take any props from template data attributes and json_data object
      const props: VideosStripProps = JSON.parse(JSON.stringify(mountPoint.dataset));
      const video_strips = contentStripData['videos_strips'];

      let videosData;

      // if the mountPoint has an id, look for a video strip with a corresponding slug
      if (mountPoint.hasAttribute('id')) {
        videosData = video_strips.filter((video_strip: {'slug': string}) => video_strip['slug'] == mountPoint.id)[0];
      } else {
        // else go in order of video strip
        videosData = video_strips[index];
      }

      props.carouselTitle = videosData['title'];
      props.initialVideos = videosData['content'];
      props.seeAllUrl = videosData['title_link'];
      const root = createRoot(mountPoint!);
      root.render(<VideosStrip {...props} />);
    });
  }
};

export { init };
