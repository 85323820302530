// based on https://codepen.io/heydon/pen/QqzRvQ/
// and our passport-faq component

interface CacheExpectations {
  transcriptRevealBtn?: HTMLButtonElement;
  transcript?: HTMLDivElement;
}

const cache: CacheExpectations = {};

/**
 * Saves re-used elements.
 */
const setupCache = () => {
  cache.transcriptRevealBtn = document.querySelector('.transcript__reveal-btn');
  cache.transcript = document.querySelector('.transcript');
};

/**
 * Listens for clicks on reveal button.
 */
const addEventListeners = () => {
  if (cache.transcriptRevealBtn) {
    cache.transcriptRevealBtn.addEventListener('click', toggleTranscript)
  }
};

/**
 * Shows or hides transcript.
 */
const toggleTranscript = (e: Event) => {
  const button = e.currentTarget as HTMLButtonElement;
  const expanded = button.getAttribute('aria-expanded') === 'true';
  const plusSign: SVGElement = button.querySelector('.expand');
  const minusSign: HTMLElement = button.querySelector('.collapse');

  button.setAttribute('aria-expanded', `${!expanded}`);
  cache.transcript.hidden = expanded;

  plusSign.classList.toggle('is-hidden');
  minusSign.classList.toggle('is-hidden');
}

/**
 * Initializes component.
 */
 const init = (): void => {
  setupCache();
  addEventListeners();
};

export { init };
