import React, { useState, useEffect, useCallback } from 'react';
import Tippy from '@tippyjs/react';
import { createRoot } from 'react-dom/client';

import {
  isLocalStorageSupported,
  isSessionStorageSupported,
  storageAvailable,
  getItemValue,
  setItem,
} from 'scripts/utils/storage';
import { customEvents } from 'scripts/custom-events';
import { useInterval } from 'scripts/utils/useInterval';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { ReactComponent as CheckMark } from 'svg/pbs-check.svg';
import { ReactComponent as Gear } from 'svg/pbs-settings-gear.svg';
import { ReactComponent as Close } from 'svg/pbs-close.svg';

interface CacheExpectations {
  ContinuousPlayControlPlaceholder?: HTMLDivElement;
  userStatus?: string;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.ContinuousPlayControlPlaceholder = document.querySelector(
    '.video-player__continuous-play-control__toggle'
  );
};

interface DepFrame {
  // this is here just for testing / depedency injection, so not going to worry about it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postMessage: jest.Mock<any, any>;
}

interface ContinuousPlayControlProps {
  depInitialCheckedState?: boolean;
  depFrame?: DepFrame;
  labelledby?: string;
  gtmLabel?: string;
  // this prop is only used for testing; it's needed so that we
  // don't have to deal with trying to render and click into a tooltip
  // in react-testing-library--this is proving to be way too difficult.
  // it forces the width of the component to be mobile, so we are just
  // testing the controls, rather than the tooltip aspect of the component.
  forceWidthForTesting?: boolean;
  // similarly, we need to give our tests a userStatus so that they're
  // not waiting for a nonexistent profile call before rendering
  forceUserStatusForTesting?: boolean;
}

const ContinuousPlayControl: React.FC<ContinuousPlayControlProps> = (props) => {
  // we're reading from localstorage initially.
  // in order not to do this every time the component updates,
  // we make this a function, which we refer to in useState.
  // see https://egghead.io/lessons/react-store-values-in-localstorage-with-the-react-useeffect-hook
  // around 1:32.
  // note - we default to this being enabled, which is governed by the
  // fallback of `true` here
  const initalCheckedState = () => {
    // we default to true;
    // being more verbose with this check because using nullish coalescing
    // operator (aka ??) was causing a sentry error
    let initCheckedState: boolean = (props.depInitialCheckedState === false) ? props.depInitialCheckedState : true;

    // only if the user has set it to off do we set to false
    if (getItemValue('continuousPlayEnabled') === false) {
      initCheckedState = false;
    }

    return initCheckedState;
  };

  // Similarly, we have to get an initial state for the "Include Passport videos" checkbox
  // This will only be relevant if Continuous Play itself is set to true
  const initialPassportState = (): boolean => {
    // by default, it is set to true
    let initPassportAllowed = true;

    // if we have the opposite preference set in localStorage,
    // or if Continuous Play itself is off, flip it
    if (getItemValue('continuousPlayPassportIncluded') === false || getItemValue('continuousPlayEnabled') === false) {
      initPassportAllowed = false;
    }

    return initPassportAllowed;
  }

  const initialUserStatusState = (): string => {
    // if this optional prop is passed, we're in a test, and we
    // want the userStatus to be 'default'
    if (props.forceUserStatusForTesting) {
      return 'default';
    } else {
      return cache.userStatus;
    }
  }

  const [checked, setChecked] = useState(initalCheckedState());
  const [includePassport, setIncludePassport] = useState(initialPassportState());
  const [userStatus, setUserStatus] = useState(initialUserStatusState());

  let width = useWindowWidth();
  // in testing, we always want to use the mobile layout
  // this prop only gets used in continuous-play-control.test.tsx
  if (props.forceWidthForTesting) {
    width = 768;
  }

  // sending the postmessage to player
  const sendPostMessage = useCallback((messageType: string) => {
    // if a user has disabled Continous Play in the Player UI
    // Session storage will have `hide-continuous-play`
    // see continuous-play.js
    if (
      isSessionStorageSupported &&
      storageAvailable('sessionStorage') &&
      sessionStorage.getItem('hide-continuous-play') === `true`
    ) {
      return null;
    }

    const player = props.depFrame || window.frames['player'];
    let message = '';

    // format the two types of postMessages
    if (messageType === 'continuousPlay') {
      message = JSON.stringify({
        command: checked ? 'enableContinuousPlay' : 'disableContinuousPlay',
      });
    } else if (messageType === 'includePassport') {
      message = JSON.stringify({
        command: includePassport ? 'includePassportContinuousPlay' : 'excludePassportContinuousPlay',
      });
    }

    if (player && typeof player.postMessage === `function`) {
      player.postMessage(message, '*');
    }
  }, [checked, includePassport, props.depFrame]);

  useEffect(() => {
    // when the continuous play state changes, we update localStorage
    // and  notify player with a postMessage
    setItem('continuousPlayEnabled', checked as unknown as string);
    sendPostMessage('continuousPlay');

    // we also want to adjust the Passport checkbox relative to the overall toggle
    if (checked) {
      // when Continuous Play is turned on, 'include Passport videos' should be checked by default
      // unless saved in localStorage as false
      if (getItemValue('continuousPlayPassportIncluded')) {
        setIncludePassport(true);
      }
    } else {
      // when Continuous Play is turned off, 'include Passport videos' gets unchecked too
      setIncludePassport(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    // when the 'include Passport' state changes, we update localStorage
    // and  notify player with a postMessage
    setItem('continuousPlayPassportIncluded', includePassport as unknown as string);
    sendPostMessage('includePassport');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includePassport]);

  // updates player every 5 seconds with current status of the toggle & checkbox
  useInterval(() => {
    sendPostMessage('continuousPlay');
    sendPostMessage('includePassport');
  }, 5000);

  // checks for updates to cached userStatus ("passport" or "default")
  useInterval(() => {
    setUserStatus(cache.userStatus);
  }, 1000);

  // kill this component if local storage isn't supported
  if (!isLocalStorageSupported || !storageAvailable('localStorage')) {
    return null;
  }

  // define the Continuous Play Controls as a React Fragment - it will appear
  // on its own at lower breakpoints, and contained in a Tippy at higher breakpoints
  const controls = (
    <React.Fragment>
      <div className="video-player__continuous-play-control__container">
        <h2
          className="video-player__continuous-play-control__label"
          id="video-player__continuous-play-control__label"
        >
          Continuous Play:
        </h2>
        <button
          role="switch"
          aria-labelledby={props.labelledby}
          aria-checked={checked}
          onClick={() => setChecked(checked ? false : true)}
          data-gtm-action={`Up Next | Continuous Play ${checked ? `Off` : `On`}`}
          data-gtm-label={props.gtmLabel}
          className="continuous-play-control__toggle an-51_4"
        >
          <span className="enabled">On</span>
          <span className="disabled">Off</span>
        </button>
      </div>
      {userStatus === 'default' && (
        <>
          <button
            role="switch"
            aria-labelledby="continuous-play-control__passport-label"
            aria-checked={includePassport}
            onClick={() => setIncludePassport(includePassport ? false : true)}
            className={`continuous-play-control__passport-checkbox ${checked ? `clickable` : `disabled`}`}
          >
            <span className="continuous-play-control__passport__box-shape"></span>
            {includePassport && (
              <CheckMark />
            )}
            <span id="continuous-play-control__passport-label">Include Passport videos</span>
          </button>
          <button className="continuous-play-control__popover-close">
            <Close />
          </button>
        </>
      )}
    </React.Fragment>
  );

  const controlsTooltip = (
    <Tippy
      appendTo="parent"
      aria={{
        content: null,
        expanded: false,
      }}
      arrow={true}
      className="continuous-play-control__settings-popover popover--show-theme"
      content={controls}
      delay={[100, 100]}
      interactive={true}
      maxWidth={310}
      onShow={(instance) => {
        instance.popper.querySelector('.continuous-play-control__popover-close').addEventListener(
          'click', () => {
            instance.hide();
          }
        );
      }}
      onHide={(instance) => {
        instance.popper.querySelector('.continuous-play-control__popover-close').removeEventListener(
          'click', () => {
            instance.hide();
          }
        );
      }}
      placement="top"
      touch={false}
      trigger="focus click manual"
      zIndex={4}
    >
      <h2 className="video-player__continuous-play-control__label continuous-play-control__settings-label">
        <Gear />
        <span className="continuous-play-control__settings-label-text">Continuous Play Settings</span>
      </h2>
    </Tippy>
  );

  if (width < 1024 || userStatus === 'passport') {
    return controls;
  } else if (userStatus === 'default') {
    return controlsTooltip;
  } else {
    // Don't render any controls if we don't know the userStatus yet;
    // This prevents flickering between the controls tooltip and the plain controls.
    return null;
  }
};

// The component needs the userStatus ("default" or "passport") as state, so we save it in the cache
// to be accessed by the component once it is rendered.
const setUserStatus = (status: string) => {
  cache.userStatus = status;
}

// These event listeners must be added outside the rendered component;
// we miss the events happening if we wait to add the event listener until after the component is rendered.
const addEvents = () => {
  window.addEventListener(customEvents.userIsPassport, () => setUserStatus('passport'));
  window.addEventListener(customEvents.userIsNotPassport, () => setUserStatus('default'));
  window.addEventListener(customEvents.userIsNotLoggedIn, () => setUserStatus('default'));
}

const renderContinuousPlayControl = () => {
  if (cache.ContinuousPlayControlPlaceholder) {
    const gtmLabel = cache.ContinuousPlayControlPlaceholder.dataset.gtmLabel;
    const root = createRoot(cache.ContinuousPlayControlPlaceholder!);
    root.render(
      <ContinuousPlayControl
        labelledby="video-player__continuous-play-control__label"
        gtmLabel={gtmLabel}
      />
    );
  }
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();
  addEvents();
  renderContinuousPlayControl();
};

export { init, ContinuousPlayControl };
